import React, { useState } from 'react';
import Login from '../../components/Login'
import background from "../../assets/img/background.png" //Background
import backgroundMobile from "../../assets/img/backgroundmobile.png"
import overlay from "../../assets/img/overlay.png" //overlay
import logo from '../../assets/img/logo.png'; //Logo
import Carousel from '../../components/Carousel'
import Data from '../../components/Data'
import DataMobile from '../../components/Data/mobile'
import Menu from '../../components/Menu'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Index(props) {
    const appName = 'Login'
    const [showLogin, setShowLogin] = useState(false)
    const deskripsi = "Selamat datang kembali di"
    const title1 = "MADRASAH SUMBAR";
    const title2 = ""
    const slogan = "Tranformasi Layanan Cepat, Tepat dan Akurat"
    const copyright = "2022 Madrasah Sumbar. - Didukung oleh";
    return (
        <>
            {/* {  <div style={{position:'sticky', top: 0, zIndex: 99, backgroundColor: '#fff', padding: 8, width: '100%', textAlign: 'center', fontWeight: 'bold'}}> */}
        {/* <a href="/skl/" >Pengumuman Kelulusan Siswa/i Madrasah DIY Silahkan klik disini</a> */}
    {/* </div> } */}
            <div className="col-12 hero-container">
                <div style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.45) 80%, rgba(255, 255, 255, 0) 100%), url(${background})` }} className="hero-background col-12"></div>
                <div style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.45) 80%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})` }} className="hero-background hero-bg-mobile col-12"></div>
                <div className="col-12 hero" style={{ backgroundPosition: '0 100%', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 80%, rgba(255, 255, 255, 0) 100%), url(${props.overlay})` }}>
                    <div className="" >
                        <div className="text-light" data-page={appName} style={{}}>
                            <div className='menu' style={{ textAlign: 'right', textColor: 'white' }}>
                                <Menu />
                            </div>
                            <div className='title-mobile'>
                                <img src={logo} alt={appName} style={{ width: 90, height: 80 }} />
                                <br></br>
                                <br></br>
                                <h1 style={{ fontSize: '200%' }}>
                                    {title1}
                                </h1>
                                <i>
                                    {slogan}
                                </i>
                                <br></br>
                                <div className='login' style={{ display: 'inline-table', width: '50px', padding: '5px 50px 5px 50px', marginTop: '20px', marginBottom: '20px', marginRight: '5px', cursor: 'pointer', background: 'green', borderRadius: 20 }} onClick={() => setShowLogin(true)}>
                                    Login
                                </div>
                                {/* <div className='ppdb' style={{ display: 'inline-table', width: '50px', padding: '0 50px 0 50px', marginTop: '20px', marginBottom: '20px', marginLeft: '5px', cursor: 'pointer', background: 'green', borderRadius: 10 }}>
                                    <a href='https://portal-ppdb.jogjamadrasahdigital.net/' target="_blank" style={{ color: 'white', 'text-decoration': 'none' }}>PPDB</a>
                                </div> */}
                            </div>
                            <div className='header' style={{}}>
                                <div style={{ flex: 1 }}>
                                </div>
                                <NavDropdown
                                    id="nav-dropdown-dark-example"
                                    title="PPDB"
                                    menuVariant="light"
                                    style={{ padding: '0 50px 0 50px', whiteSpace: 'nowrap' }}
                                >
                                    <NavDropdown.Item href="http://ppdb.madrasahsumbar.net/" target="_blank">PPDB Reguler</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="http://ppdb.jogjamadrasahdigital.net/" target="_blank">PPDB JPTT</NavDropdown.Item> */}

                                </NavDropdown>
                                {/* <div className='ppdb' style={{ padding: '0 50px 0 50px', whiteSpace: 'nowrap' }}>
                                    <a href='https://portal-ppdb.jogjamadrasahdigital.net/' target="_blank" style={{ color: 'white', 'text-decoration': 'none' }}>PPDB</a>
                                </div> */}
                                <div className='login' style={{ padding: '0 50px 0 50px', cursor: 'pointer', background: 'green', borderRadius: 50 }} onClick={() => setShowLogin(true)}>
                                    Login
                                </div>
                            </div>
                            <div className="">
                                <div className="title" style={{}}>
                                    <img src={logo} alt={appName} style={{ width: 110, height: 100 }} />
                                    <h1 style={{ fontSize: '400%', marginLeft: 50 }}>
                                        {title1}
                                    </h1>
                                </div>
                                <div className="deskripsi" style={{}}>
                                    <img src={overlay} alt={appName} style={{ width: 500 }} />
                                    <div className="slogan" >
                                        <p style={{ fontSize: '300%', marginLeft: 200, marginRight: 200, marginTop: 80, textAlign: 'left' }}>
                                            {slogan}
                                        </p>
                                    </div>
                                </div>
                                <div className="data-container mb-5">
                                    <Data />
                                    <Carousel />
                                    <DataMobile />
                                </div>
                            </div>
                        </div>
                    </div>

                    {showLogin && <div onClick={e => setShowLogin(false)} style={{ position: 'fixed', backdropFilter: 'blur(4px)', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ maxWidth: '600px', width: '100%' }} onClick={e => e.stopPropagation()}>
                            <Login />
                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
}

export default Index;