import React, { useState } from 'react';
// import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Login from '../../components/Login';
import NavDropdown from 'react-bootstrap/NavDropdown';


// const options = [
//     'PPDB',
//     'LOGIN',
// ];

const ITEM_HEIGHT = 48;

export default function LongMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showLogin, setShowLogin] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <div style={{ color: "white" }}>
                    <MoreVertIcon />
                </div>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 2.5,
                        width: '15ch',
                    },
                }}
            >
                <MenuItem onClick={handleClose}><a href='https://ppdb.madrasahsumbar.net/' target="_blank" style={{ color: 'black', 'text-decoration': 'none' }}>PPDB Reguler</a></MenuItem>
                {/* <MenuItem onClick={(handleClose)}><a href='http://jogjamadrasahdigital.net/ppdb/jptt/' target="_blank" style={{ color: 'black', 'text-decoration': 'none' }}>PPDB JPTT</a></MenuItem> */}
            </Menu>
            {showLogin && <div onClick={e => setShowLogin(false)} style={{ position: 'fixed', backdropFilter: 'blur(4px)', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <div style={{ textAlign: 'left', color: 'black', maxWidth: '600px', width: '100%' }} onClick={e => e.stopPropagation()}>
                    <Login />
                </div>
            </div>}
        </div>
    );
}
